import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";

import StoryDetailForm from "./canvasStoryForm";
import usePageshook from "./hook";
import DropBox from "../../common/drop-zone";
import TextEditorComponent from "./TextEditors";

import ImageUpload from "../../common/editor/imageCrop";
import Timeline from "./timeline";
import Swal from "sweetalert2";
import { convertToSlug } from "../../common/count-words-and-characters";
import PublishForm from "./publish";
import AppContext from "../../appContext";
import EditorNotes from "./editorNotes";
import { CheckAccess } from "../../common/utils/roleAccess";
import { GetNewCustomSlug } from "../../common/api/api";
import config from "../../../config";
import { CreateDataApi, UpdateDataApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import moment from "moment";

const CreateStory = () => {
  const {
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    includeSectionList,
    setIncludeSectionList,
    authorList,
    setAuthorList,
    selectedAuthorList,
    setSelectedAuthorList,
    HandleSelectChange,
    selectedIncludeSection,
    setSelectedIncludeSection,
    HandleUploadImage,
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    submittedPage,
    formData,
    setFormData,
    socialCard,
    setSocialCard,
    handleAddElement,
    handleDeleteElement,
    handleSocialCardChange,
    show,
    setShow,
    handleClose,
    handleShow,
    HandleModalDataSubmit,
    id,
    onUpdate,

    cards,
    setCards,
    HandleGetSection,
    slug,
    setSlug,
    currentImgId,
    setCurrentImgId,
    imgUrl,
    setImgUrl,
    showTimeline,
    setShowTimeline,
    timelines,
    loading,
    hasEmptyField,
    RemoveEmptyFields,
    getValues,
    isImgEditorShown,
    setIsImgEditorShown,
    handleSubCardChange,
    onUpdateStatus,
    storyStatus,
    setStoryStatus,
    navigate,
    fetchTimeline,
    onAutoSaveStory,
    setLoading,
    statusRef,
    isSaving,
    FetchTagsList,
    fetchOurSection,
    editEnabled,
    setEditEnabled,
    isEditingById,
    setIsEditingById,
    populateManageData,
    setPopulateManageData
  } = usePageshook();
  const { showEditorNotes, setShowEditorNotes, appData } =
    useContext(AppContext);

  useEffect(() => {
    setValue('story_type', 'Text Story')
    const handleBackButtonClick = (event) => {
      stopEditing();
      window.history.back();
    };

    window.addEventListener('popstate', handleBackButtonClick);

    window.history.pushState(null, '', window.location.pathname);

    window.addEventListener('beforeunload', handleBackButtonClick);

    return () => {
      window.removeEventListener('popstate', handleBackButtonClick);
      window.removeEventListener('beforeunload', handleBackButtonClick);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const handleCloseCanvas = () => setOpenCanvas(false);
  const [totalWordsCount, setTotalWordsCount] = useState(0)
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImgEditorShownForEditor, setIsImgEditorShownForEditor] =
    useState(false);
  const [cardIndexObj, setCardIndexObj] = useState({
    cardIndex: 0,
    subCardIndex: 0,
  });
  const fetchNewCustomSlugData = async (item) => {
    try {
      // const response = await GetNewCustomSlug({
      //   slug: item,
      //   id: appData?.story_Id,
      // });
      const response = {
        data: {
          new_custom_slug: item
        }
      }

      setValue("custom_slug", response?.data?.new_custom_slug);
      let convertedSlug = convertToSlug(response?.data?.new_custom_slug);
      const updatedCustomSlug = slug
        ? `${slug?.split("/")?.reverse()?.join("/")}/${convertedSlug}`
        : `${convertedSlug}`;
      setValue("slug_preview", updatedCustomSlug?.toLowerCase());
      setValue("slug", `${convertedSlug?.toLowerCase()}`);
    } catch (error) {
      console.error("Error fetching new custom slug:", error.message);
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValue("status", "open");
    setStoryStatus("open");
    if (name === "title") {
      setValue("title", value);
    }

    if (name === "sub_title") {
      setValue("sub_title", value);
    }

    if (name === "is_breaking_news") {
      setValue("is_breaking_news", value)
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "custom_slug") {
      let convertedSlug = convertToSlug(value);
      const updatedCustomSlug = slug
        ? `${slug?.split("/")?.reverse()?.join("/")}/${convertedSlug}`
        : `${convertedSlug}`;
      setValue("slug_preview", updatedCustomSlug);
      setValue("slug", `${updatedCustomSlug}`);
    }
  };
  const [files, setFiles] = useState([]);

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const textareaRef = useRef(null);

  const showError = (data) => { };
  useEffect(() => {
    if (!id) {
      window.onload();
    }
  }, [id]);

  const calculateTextareaRows = () => {
    const numberOfNewlines = (getValues()?.title?.match(/\n/g) || []).length;
    const calculatedRows = Math.max(2, numberOfNewlines + 1);
    return getValues()?.title?.length > 63
      ? calculatedRows + 2
      : calculatedRows;
  };
  const calculateSubTextareaRows = () => {
    const numberOfNewlines = (getValues()?.sub_title?.match(/\n/g) || [])
      .length;
    const calculatedRows = Math.max(2, numberOfNewlines + 1);
    return getValues()?.sub_title?.length > 98
      ? calculatedRows + 2
      : calculatedRows;
  };


  const getChildStoryId = async () => {
    setLoading(true)

    try {
      let childStoryId = null

      if (formData?.child_story_id) {
        childStoryId = formData?.child_story_id
      }
      else if (formData.status === 'published' || (formData.status === 'scheduled' && formData?.publish_date <= new Date().toISOString())) {
        const response = await fetch(`${config.apiUrl}/stories/copy-story/${formData?._id}`)
        const responseData = await response.json()
        const copyStory = responseData?.story

        const cards = copyStory?.cards
        let newCards = []

        for (let card of cards) {
          delete card._id
          delete card.id
          const response = await CreateDataApi(`card/create`, card);

          newCards.push(response?.savedCard?._id)
        }

        copyStory.cards = newCards

        delete copyStory._id
        delete copyStory.id

        copyStory.is_child_story = true
        copyStory.parent_story_id = formData._id
        copyStory.status = 'open'
        copyStory.slug = copyStory?.slug + '-child-story'
        copyStory.title = copyStory.title + ' '
        copyStory.is_editing_by = formData?.user?._id
        copyStory.updated_on = new Date().toISOString()

        const childStory = await CreateDataApi(`stories/create-child-story`, copyStory);

        await Promise.allSettled([
          UpdateDataApi(`${apiEndpoints?.storiesById(formData?._id)}`, { child_story_id: childStory?.story?._id, updated_on: new Date().toISOString(), saveToCache: false })
          ,
          CreateDataApi(apiEndpoints?.timeline, {
            story_id: copyStory.parent_story_id,
            cloned_story_id: childStory?.story?._id,
            action_by: formData?.user?._id,
            action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
            action_type: `cloned`,
          })
        ])

        childStoryId = childStory?.story?._id
      }

      if (formData.status !== 'published' && !(formData?.status === 'scheduled' && formData?.publish_date <= new Date().toISOString())) {
        childStoryId = formData?._id
      }

      const url = formData?.story_type === 'Text Story' ? `/story/update/${childStoryId}` : formData?.story_type === 'Web Story' ? `/web-story/update/${childStoryId}` : formData?.story_type === 'Photo Story' ? `/photo-story/update/${childStoryId}` : `/video-story/update/${childStoryId}`

      if (childStoryId) localStorage.setItem('updateStoryId', childStoryId)

      setLoading(false)
      navigate(url)
    }
    catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const enableEditing = async () => {
    getChildStoryId()
  }

  const stopEditing = async () => {
    setLoading(true)
    setEditEnabled(false)

    const url = `stories/${getValues()?.story_id || id}`

    const response = await UpdateDataApi(url, { is_editing_by: null, updated_on: new Date().toISOString() });

    if (response?._id) {
      setIsEditingById(null)
      setFormData({ ...formData, is_editing_by: null })
    }

    setLoading(false)
  }

  const startEditing = async () => {
    if (formData?.status === 'published' || (formData?.status === 'scheduled' && formData?.publish_date <= new Date()?.toISOString())) {
      await enableEditing()
      return
    }

    setLoading(true)
    setEditEnabled(true)

    const payload = { is_editing_by: formData?.user?._id, updated_on: new Date().toISOString() }

    if (formData?.user?.user_role?.name === 'Guest Contributor') {
      payload.status = 'open'
      statusRef.current = 'open'
      setFormData({ ...formData, status: 'open' })
    }

    const url = `stories/${getValues()?.story_id || id}`

    const response = await UpdateDataApi(url, payload);

    if (response?._id) {
      setIsEditingById(formData?.user?._id)
      setFormData({ ...formData, is_editing_by: formData?.user })
    }

    if (getValues()?.story_id || id) localStorage.setItem('updateStoryId', getValues()?.story_id || id)

    setLoading(false)
  }

  useEffect(() => {
    if (cards) {
      let wordCount = 0;
      cards.forEach(card => {
        card?.sub_card?.forEach(sub_card => {
          if (sub_card?.type?.toLowerCase() === 'text') {
            const words = sub_card?.title?.trim()?.split(/\s+/);
            wordCount += words.length;
          }
        });
      });

      setTotalWordsCount(wordCount)
    }
  }, [cards]);


  return (
    <div className="content position-relative">
      {/* {!isImgEditorShown && !isImgEditorShownForEditor && ( */}
      {loading ? <div className="spinner story-form-spinner"></div> : ""}
      <div className="card col-md-12 m-auto p-30">
        <form
          onSubmit={(e) => {
            e?.preventDefault();

            if (
              !formData?.meta_title ||
              !formData?.meta_description ||
              !selectedTagsList?.length > 0 ||
              !selectedIncludeSection?.length > 0 ||
              (formData?.is_breaking_news && (!formData?.breaking_title || !formData?.breaking_description)) ||
              getValues()?.caption === "" ||
              (getValues()?.alt === "" && getValues()?.attribution === "") ||
              getValues()?.credit === "" ||
              getValues()?.meta_keywords === "" ||
              !getValues()?.file_path ||
              (formData?.is_redirect && !formData?.redirect_url) ||
              (formData?.redirect_url && !formData?.redirect_url?.startsWith('https://'))
            ) {
              handleSubmit(showError)(e);
              if (
                getValues()?.caption === "" ||
                (getValues()?.is_breaking_news && (getValues()?.attribution === "" && getValues()?.alt)) ||
                getValues()?.credit === "" ||
                !getValues()?.file_path
              ) {
                if (!getValues()?.file_path) {
                  Swal.fire({
                    title: `Add hero image!.`,
                    icon: "warning",
                  });
                  return;
                }
              } else {
                setShow(true);
                handleSubmit(showError)(e);
              }
            } else {
              let payload = getValues();
              if (!payload?.cards > 0 || !cards?.length > 0) {
                Swal.fire({
                  title: `Add atleast one cards!`,
                  icon: "warning",
                });
              } else {
                if (
                  (payload?.cards && hasEmptyField(payload?.cards)) ||
                  (cards && hasEmptyField(cards))
                ) {
                  Swal.fire({
                    title: `This action remove empty text field's.`,
                    text: "This is a permanent action",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm",
                  }).then(async (result) => {
                    if (result?.isConfirmed) {
                      let cardsAfterRemovedEmptyElement =
                        RemoveEmptyFields(cards);
                      setCards(cardsAfterRemovedEmptyElement);
                      setValue("cards", cardsAfterRemovedEmptyElement);
                      handleSubmit(id ? onUpdate : onSubmit)(e);
                    }
                  });
                } else {
                  handleSubmit(onSubmit)(e);
                }
              }
            }
          }}
          name="frmStory"
        >
          {formData?.publish_date && (
            <div className="row">
              <div className="col-md-12">
                <strong>Last Publish Date: {moment(formData?.publish_date ? formData?.publish_date : "").format("DD MMMM YYYY, hh:mm A")}</strong>
              </div>
            </div>
          )}
          <div className="card-header">
            <div className="col-sm-2 ">
              <h3 className="text-bold">{id ? `Update` : `Create`} Story</h3>
            </div>
            <div className="col-sm-7 d-flex justify-content-end ">
              {
                (isEditingById || (Object.keys(formData)?.includes('is_editing_by') && formData?.is_editing_by?._id === formData?.user?._id)) &&
                <button
                  className="btn btn-primary mr-5"
                  style={{ width: "2rem!important" }}
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault()
                    stopEditing()
                  }}
                >
                  Stop Editing
                </button>

              }

              {
                Object.keys(formData)?.includes('is_editing_by') && !formData?.is_editing_by &&
                <button
                  className="btn btn-primary mr-5"
                  style={{ width: "2rem!important" }}
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault()
                    startEditing()
                  }}
                >
                  Start Editing
                </button>
              }

              {
                ((!editEnabled && formData?.status === 'published')) || formData?.is_editing_by?._id !== formData?.user?._id ?
                  <div className="add-btns d-flex">
                    {CheckAccess("story", "create") && (
                      <button
                        className="btn btn-primary mr-5"
                        style={{ width: "2rem!important" }}
                        onClick={(e) => {
                          handleShow(e);
                        }}
                        disabled={loading}
                      >
                        Manage
                      </button>
                    )}
                  </div>

                  :

                  <div className="add-btns d-flex">
                    {CheckAccess("story", "create") && (
                      <button
                        className="btn btn-primary mr-5 border-0"
                        style={{ width: "2rem!important" }}
                        onClick={(e) => {
                          e?.preventDefault();
                          onAutoSaveStory("open");
                        }}
                        disabled={isSaving}
                      >
                        {isSaving ? `Saving...` : `Save`}
                      </button>
                    )}

                    {statusRef?.current === "open" &&
                      CheckAccess("story", "create") && (
                        <button
                          className="btn btn-primary mr-5"
                          style={{ width: "2rem!important" }}
                          type="submit"
                          disabled={loading}
                        >
                          {`Submit`}
                        </button>
                      )}
                    {statusRef?.current === "submitted" &&
                      CheckAccess("story", "approved") && (
                        <button
                          className="btn btn-primary mr-5 border-0"
                          style={{ width: "2rem!important" }}
                          onClick={(e) => {
                            e?.preventDefault();
                            if (
                              getValues()?.caption === "" ||
                              getValues()?.attribution === "" ||
                              getValues()?.credit === "" ||
                              getValues()?.file_path === ""
                            ) {
                              if (getValues()?.file_path === "") {
                                Swal.fire({
                                  title: `Add hero image!.`,
                                  icon: "warning",
                                });
                              }
                              handleSubmit(showError)(e);
                              return;
                            }
                            if (
                              !formData?.meta_title ||
                              !formData?.meta_description ||
                              !selectedTagsList?.length > 0 ||
                              !selectedIncludeSection?.length > 0
                            ) {
                              setShow(true);
                              handleSubmit(showError)(e);
                            } else {
                              let cardsAfterRemovedEmptyElement =
                                RemoveEmptyFields(cards);
                              setCards(cardsAfterRemovedEmptyElement);
                              setValue("cards", cardsAfterRemovedEmptyElement);
                              onUpdateStatus("approved");
                            }
                          }}
                          disabled={loading}
                        >
                          Approved
                        </button>
                      )}
                    {statusRef?.current === "approved" &&
                      CheckAccess("story", "published") && (
                        <button
                          className="btn btn-primary mr-5 border-0"
                          style={{ width: "2rem!important" }}
                          onClick={(e) => {
                            e?.preventDefault();
                            if (
                              // getValues()?.caption === "" ||
                              // getValues()?.attribution === "" ||
                              // getValues()?.credit === "" ||
                              getValues()?.file_path === ""
                            ) {
                              if (getValues()?.file_path === "") {
                                Swal.fire({
                                  title: `Add hero image!.`,
                                  icon: "warning",
                                });
                              }
                              handleSubmit(showError)(e);
                              return;
                            }
                            if (
                              !formData?.meta_title ||
                              !formData?.meta_description ||
                              !selectedTagsList?.length > 0 ||
                              !selectedIncludeSection?.length > 0
                            ) {
                              setShow(true);
                              handleSubmit(showError)(e);
                            } else {
                              setOpenCanvas(true);
                            }
                          }}
                          disabled={loading}
                        >
                          Publish
                        </button>
                      )}

                    {CheckAccess("story", "create") && (
                      <button
                        className="btn btn-primary mr-5"
                        style={{ width: "2rem!important" }}
                        onClick={(e) => {
                          if (populateManageData === false) {
                            setPopulateManageData(true)
                          }
                          handleShow(e);
                        }}
                        disabled={loading}
                      >
                        Manage
                      </button>
                    )}
                    {/* {statusRef?.current && timelines?.length > 0 && ( */}
                    {timelines?.length > 0 && (
                      <button
                        className="btn btn-primary mr-5"
                        style={{ width: "2rem!important" }}
                        onClick={(e) => {
                          e?.preventDefault();
                          setShowTimeline(true);
                        }}
                        disabled={loading}
                      >
                        Timeline
                      </button>
                    )}
                    <button
                      className="btn btn-primary mr-5"
                      style={{ width: "2rem!important" }}
                      onClick={(e) => {
                        e?.preventDefault();
                        navigate("/story");
                      }}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
              }
            </div>
            <div className="col-sm-12 d-flex justify-content-between align-items-center"></div>
          </div>
          <div className="card-body">
            {/* Title */}
            <p> Total Words: <span className="fw-bold ml-5">{totalWordsCount}</span></p>

            {
              formData?.is_editing_by && formData?.is_editing_by?._id !== formData?.user?._id &&
              <p> Story is editing by: <span className="fw-bold ml-5">{formData?.is_editing_by?.name}</span></p>
            }

            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label text-start"
              >
                Title<span className="text-danger">*</span>
                <p>{`${formData?.title?.length || 0}/110`}</p>
              </label>
              <div className="col-sm-7">
                <Form.Control
                  disabled={!editEnabled}
                  ref={textareaRef}
                  as="textarea"
                  name="title"
                  placeholder="Title"
                  maxLength={110}
                  {...register("title", {
                    required: true,
                    maxLength: 110,
                  })}
                  onChange={handleChange}
                  onBlur={(e) => {
                    e?.preventDefault();
                    onAutoSaveStory();
                  }}
                  rows={calculateTextareaRows()}
                  // rows={2}
                  className={
                    id
                      ? "border-0 hero-input textarea-height text-area-style auto-resize-textarea"
                      : "border-0 hero-input textarea-height auto-resize-textarea"
                  }
                  isInvalid={formData?.title?.length > 110}
                  value={formData?.title}
                />
                {errors.title && (
                  <span className="text-danger">Title is required</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label text-start"
              >
                Sub Title<span className="text-danger">*</span>
                <p>{`${formData?.sub_title?.length || 0}/200`}</p>
              </label>
              <div className="col-sm-7">
                <Form.Control
                  disabled={!editEnabled}
                  ref={textareaRef}
                  as="textarea"
                  name="sub_title"
                  placeholder="Sub Title"
                  className={
                    id
                      ? "form-control border-0 hero-sub-input textarea-height text-area-style1"
                      : "form-control border-0 hero-sub-input textarea-height"
                  }
                  maxLength={200}
                  {...register("sub_title", {
                    required: true,
                    maxLength: 200,
                  })}
                  value={formData?.sub_title}
                  onChange={handleChange}
                  onBlur={(e) => {
                    e?.preventDefault();
                    onAutoSaveStory();
                    autoResizeTextarea(e.target);
                  }}
                  rows={calculateSubTextareaRows()}
                />
                {errors.sub_title && (
                  <span className="text-danger">Sub title is required</span>
                )}
              </div>
            </div>
            <DropBox
              errors={errors}
              register={register}
              handleChange={handleChange}
              setValue={setValue}
              imgUrl={imgUrl}
              setImgUrl={setImgUrl}
              files={files}
              setFiles={setFiles}
              setIsImgEditorShown={setIsImgEditorShown}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              currentImgId={currentImgId}
              setCurrentImgId={setCurrentImgId}
              isLoading={loading}
              setIsLoading={setLoading}
              getValues={getValues}
              onAutoSaveStory={onAutoSaveStory}
              editEnabled={editEnabled}
            />
            <>
              <StoryDetailForm
                editEnabled={editEnabled}
                FetchTagsList={FetchTagsList}
                timelines={timelines}
                fetchNewCustomSlugData={fetchNewCustomSlugData}
                placement={"end"}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                setValue={setValue}
                handleChange={handleChange}
                handleClose={handleClose}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                formData={formData}
                setFormData={setFormData}
                tagsList={tagsList}
                setTagsList={setTagsList}
                selectedTagsList={selectedTagsList}
                setSelectedTagsList={setSelectedTagsList}
                includeSectionList={includeSectionList}
                setIncludeSectionList={setIncludeSectionList}
                selectedIncludeSection={selectedIncludeSection}
                authorList={authorList}
                setAuthorList={setAuthorList}
                selectedAuthorList={selectedAuthorList}
                setSelectedAuthorList={setSelectedAuthorList}
                HandleSelectChange={HandleSelectChange}
                socialCard={socialCard}
                handleAddElement={handleAddElement}
                handleDeleteElement={handleDeleteElement}
                handleSocialCardChange={handleSocialCardChange}
                HandleModalDataSubmit={HandleModalDataSubmit}
                HandleGetSection={HandleGetSection}
                slug={slug}
                onAutoSaveStory={onAutoSaveStory}
                setSlug={setSlug}
                statusRef={statusRef}
                fetchOurSection={fetchOurSection}
              />
            </>
          </div>
        </form>
        <div className="row mb-15 ml-5-p">
          <TextEditorComponent
            editEnabled={editEnabled}
            setValue={setValue}
            cards={cards}
            imgUrl={imgUrl}
            setImgUrl={setImgUrl}
            setCards={setCards}
            isImgEditorShown={isImgEditorShown}
            setIsImgEditorShown={setIsImgEditorShown}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setIsImgEditorShownForEditor={setIsImgEditorShownForEditor}
            handleSubCardChange={handleSubCardChange}
            setCardIndexObj={setCardIndexObj}
            currentImgId={currentImgId}
            setCurrentImgId={setCurrentImgId}
            errors={errors}
            register={register}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getValues={getValues}
            onAutoSaveStory={onAutoSaveStory}
          />
        </div>
      </div>
      {/* )} */}
      {/* {(isImgEditorShown || isImgEditorShownForEditor) && ( */}
      <>
        <Modal
          // {...props}
          show={isImgEditorShown}
          // fullscreen={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          onHide={() => setIsImgEditorShown(false)}
          dialogClassName="story-popup"
          contentClassName="story-content-popup"
        >
          <Modal.Body>
            <ImageUpload
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              isImgEditorShown={isImgEditorShown}
              setIsImgEditorShown={setIsImgEditorShown}
              isImgEditorShownForEditor={isImgEditorShownForEditor}
              setIsImgEditorShownForEditor={setIsImgEditorShownForEditor}
              setValue={setValue}
              setImgUrl={setImgUrl}
              handleSubCardChange={handleSubCardChange}
              cardIndexObj={cardIndexObj}
              currentImgId={currentImgId}
              setCurrentImgId={setCurrentImgId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Modal.Body>
        </Modal>
        <Modal
          // {...props}
          show={isImgEditorShownForEditor}
          // fullscreen={true}
          size="lg"
          onHide={() => setIsImgEditorShown(false)}
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          dialogClassName="story-popup"
          contentClassName="story-content-popup"
        >
          <Modal.Body>
            <ImageUpload
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              isImgEditorShown={isImgEditorShown}
              setIsImgEditorShown={setIsImgEditorShown}
              isImgEditorShownForEditor={isImgEditorShownForEditor}
              setIsImgEditorShownForEditor={setIsImgEditorShownForEditor}
              setValue={setValue}
              setImgUrl={setImgUrl}
              handleSubCardChange={handleSubCardChange}
              cardIndexObj={cardIndexObj}
              currentImgId={currentImgId}
              setCurrentImgId={setCurrentImgId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Modal.Body>
        </Modal>
      </>
      {timelines?.length > 0 && (
        <Timeline
          showTimeline={showTimeline}
          setShowTimeline={setShowTimeline}
          timelines={timelines}
        />
      )}{" "}
      {/* offcanvas start from here manage part start */}
      <PublishForm
        storyId={getValues()?.story_id ? getValues()?.story_id : ""}
        handleCloseCanvas={handleCloseCanvas}
        openCanvas={openCanvas}
        setOpenCanvas={setOpenCanvas}
        setStoryStatus={setStoryStatus}
        fetchTimeline={fetchTimeline}
        getValues={getValues}
        setLoading={setLoading}
        formData={formData}
        onAutoSaveStory={onAutoSaveStory}
      />
      <EditorNotes
        storyId={getValues()?.story_id ? getValues()?.story_id : ""}
        showEditorNotes={showEditorNotes}
        setShowEditorNotes={setShowEditorNotes}
        setStoryStatus={setStoryStatus}
      />
    </div>
  );
};

export default CreateStory;
