import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../config";
import Swal from "sweetalert2";
import { toast } from "react-toastify"; // Import react-toastify
import { useForm } from "react-hook-form";
// import React from "react";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { CreateDataApi, GetListApi } from "../../common/api/api";
import { convertToSlug } from "../../common/utils/convertToSlug";
import _ from "lodash";
import moment from "moment";

const useTagsHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [tagsList, setTagsList] = useState();
  const [sectionItem, setSectionItem] = useState();
  const handleClose = (e) => setShow(false);
  const [show, setShow] = useState(false);

  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;

  const [sectionView, setSectionView] = useState([]);

  const fetchTagsById = async () => {
    try {
      axios
        .get(`${config.apiUrl}/tags/${id}`)
        .then((response) => {
          if (response?.data) {
            setSectionView(response?.data);
            let length = response?.data.parent_section.length - 1;
            setValue("parent_section", response?.data.parent_section[length]);
          }
        })
        .catch((error) => { });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTagsById();
    }
  }, [id]);

  const generateSlug = (e) => {
    let slug = convertToSlug(e?.target?.value);
    setValue("slug", slug);
  };

  const onSubmit = (data) => {
    data.created_by = user?.name;
    data.created_on = moment().format("MMMM, DD YYYY");

    axios
      .post(`${config.apiUrl}/tags`, data)
      .then((response) => {
        setIsOpenCanvas(false);
        if (response.data) {
          setShow(false);
          fetchTagsList();
          Swal.fire({
            icon: "success",
            title: "Tag added successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        if (error.response?.data?.error === "Tag already exists") {
          toast.error("Tag already exists", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        } else {
          toast.error("Error adding tag. Please try again.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
          });
        }
        console.error("Error adding tags:", error);
      });
  };

  const onUpdate = async (data) => {
    data.updated_by = user?.name;
    data.updated_on = moment().format("MMMM, DD YYYY");

    await axios
      .put(`${config.apiUrl}/tags/${sectionItem?._id}`, data)
      .then((response) => {
        if (response?.data) {
          fetchTagsList();
          setIsOpenCanvas(false);
          setShow(false);
          Swal.fire({
            icon: "success",
            title: "Tags updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.error("Error updating tags:", error);
      });
  };

  useEffect(() => {
    fetchTagsList();
  }, [currentPage]);

  const fetchTagsList = async (name) => {
    const response = await GetListApi(`${apiEndpoints.tags}`, {
      name: name,
      page: currentPage,
      pageSize: 20,
    });
    if (response) {
      setTagsList(response);
      setTotalPages(response?.length || 0);
    }
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios
          .delete(`${config.apiUrl}/tags/${id}`)
          .then((response) => {
            fetchTagsList();
            setIsOpenCanvas(false);
            setShow(false);
            Swal.fire({
              title: "Item deleted successfully!",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
          })
          .catch((error) => {
            if (error.response?.data?.error === "Cannot delete tag as it is associated with stories") {
              toast.error("Cannot delete tag as it is associated with stories", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
              });
            } else {
              console.error("Error deleting item:", error);
              // Handle error, show error message, etc.
              toast.error("An error occurred while deleting");
            }
          });
      }
    });
  };
  const ontagsListUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios
        .put(`${config.apiUrl}/${apiEndpoints?.section}`, {
          draggedFrom: data?.draggedFrom,
          draggedTo: data?.draggedTo,
          originalOrder: data?.originalOrder,
        })
        .then((response) => {
          if (response.data) {
            fetchTagsList();
            toast.success("Section updated successfully.");
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    onSubmit,
    onUpdate,
    navigate,
    register,
    handleSubmit,
    sectionView,
    setSectionView,
    errors,
    handleDelete,
    tagsList,
    setTagsList,
    sectionItem,
    setSectionItem,
    setValue,
    isOpenCanvas,
    setIsOpenCanvas,
    onUpdate,
    fetchTagsList,
    ontagsListUpdate,
    currentPage,
    totalPages,
    setCurrentPage,
    handleClose,
    show,
    setShow,
    getValues,
    generateSlug,
  };
};

export default useTagsHook;
