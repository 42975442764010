import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import config from "../../../config";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const useOpinionPollHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [formData, setFormData] = useState({
    topic: '',
    duration: '',
    errors: {},
    status: 'Active',
    counts: {}
  })

  const [startDate, setStartDate] = useState('')

  const durationOptions = []
  for (let i = 1; i <= 24; i++) durationOptions.push({ label: i, value: i })

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  const [imgUrl, setImgUrl] = useState("");
  const [opinionPolls, setOpinionPolls] = useState([]);
  const [optionArr, setOptionArr] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
  ]);
  const handleAddOptionFields = () => {
    setOptionArr((optionArr) => [
      ...optionArr,
      { id: Number(optionArr?.length + 1), value: "" },
    ]);
  };
  const handleRemoveOptionFields = (index) => {
    if (index > -1) {
      optionArr.splice(index, 1);
    }
    setOptionArr((optionArr) => [...optionArr]);
  };

  const HandleUploadImage = async (files) => {
    let data = {};
    try {
      if (files?.length > 0) {
        const formData = new FormData();
        data.status = "Active";
        const res = await axios.post(
          `${config.apiUrl}/${apiEndpoints.upload}`,
          { files: files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res?.data?.length) {
          setValue("file_name", res?.data[0]?.file_name);
          setValue("file_path", res?.data[0]?.file_path);
          setValue("file_Id", res?.data[0]?._id);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const handleOptionChange = (e, index) => {
    const updatedOptions = [...optionArr];
    updatedOptions[index] = { ...updatedOptions[index], value: e?.target?.value?.slice(0, 20) };
    setOptionArr(updatedOptions);
  };

  const validateErrors = () => {
    if (!formData?.topic) {
      setFormData(prev => ({ ...prev, errors: { ...prev.errors, topic: 'This is required' } }))
      return false
    }

    let flag = true

    optionArr?.map((option, index) => {
      if (!option?.value) {
        setFormData(prev => ({ ...prev, errors: { ...prev.errors, [`option${index}`]: 'This is required' } }))
        flag = false
      }
    })

    if (!flag) return false

    if (formData?.topic?.length > 60) {
      setFormData(prev => ({ ...prev, errors: { ...prev.errors, topic: 'Max Char Length is 60' } }))
      return false
    }

    if (!startDate) {
      setFormData(prev => ({ ...prev, errors: { ...prev.errors, startDate: 'This is required' } }))
      return false
    }

    if (!formData?.duration) {
      setFormData(prev => ({ ...prev, errors: { ...prev.errors, duration: 'This is required' } }))
      return false
    }

    if (!optionArr?.length) {
      toast.error('Options cannot be empty')
      return false
    }

    if (!formData?.status) {
      setFormData(prev => ({ ...prev, errors: { ...prev.errors, status: 'This is required' } }))
      return false
    }

    if (Object.values(formData?.counts).find(item => item > 0) && formData?.options?.length > optionArr?.length) {
      toast.error("Options count cannot be decreased")
      return false
    }

    return true
  }


  const onSubmit = async (data) => {
    if (!validateErrors()) return

    const user = JSON.parse(localStorage.getItem("user"));

    const payload = {
      topic: formData?.topic,
      status: formData?.status,
      options: optionArr,
      publish_date: new Date(startDate?.replace('at', '')),
      duration: formData?.duration,
      viewResult: getValues('viewResult'),
      created_by: user?._id,
      updated_by: user?._id,
    }

    // data.hero_image = {
    //   file_name: data?.heroImage?.file_name,
    //   file_path: data?.heroImage?.file_path,
    // };

    await axios
      .post(`${config.apiUrl}/${apiEndpoints?.opinionPolls}`, payload)
      .then((response) => {
        toast.success("Opinion poll added successfully");
        navigate("/opinion-polls");
      })
      .catch((error) => {
        console.error("Error adding work:", error);
      });
  };

  const convertPublishDateToStartDate = (publish_date) => {
    const date = new Date(publish_date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };

    const dateStringFormatted = date.toLocaleString('en-US', options);

    const parts = dateStringFormatted.replace(',', ' at').split('at')
    return parts?.[0] + parts?.[1] + ' at ' + parts?.[2]
  }


  const onUpdate = async (data) => {
    if (!validateErrors()) return

    const user = JSON.parse(localStorage.getItem("user"));

    const payload = {
      topic: formData?.topic,
      status: formData?.status,
      options: optionArr,
      publish_date: new Date(startDate?.replace('at', '')),
      duration: formData?.duration,
      viewResult: getValues('viewResult'),
      created_by: user?._id,
      updated_by: user?._id,
    }

    // if (data?.file_name && data?.file_path && data?.file_Id) {
    //   data.hero_image = {
    //     file_name: data?.heroImage?.file_name,
    //     file_path: data?.heroImage?.file_path,
    //   };
    // }

    await axios
      .put(`${config.apiUrl}/${apiEndpoints?.opinionPollsById(id)}`, payload)
      .then((response) => {
        toast.success("Opinion poll updated successfully");
        navigate("/opinion-polls");
      })
      .catch((error) => {
        console.error("Error adding work:", error);
      });
  };

  const fetchOpinonPolls = () => {
    axios
      .get(`${config.apiUrl}/opinion-polls`)
      .then((response) => {
        setOpinionPolls(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact requests:", error);
      });
  };

  useEffect(() => {
    fetchOpinonPolls();
  }, []);

  const fetchOpinionPollById = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints?.opinionPollsById(id)}`
      );
      if (response?.data) {
        const {
          data: { options, topic, description, voting, viewResult, heroImage, allowChangeVote } = {},
        } = response;

        setValue("topic", topic);
        setValue("description", description);
        setValue("voting", voting);
        setValue("viewResult", viewResult);
        setValue('heroImage', heroImage)
        setValue('allowChangeVote', allowChangeVote)
        setImgUrl(heroImage?.file_path)

        setFormData({
          topic: response?.data?.topic,
          duration: response?.data?.duration,
          isLive: response?.data?.isLive,
          status: response?.data?.status,
          counts: response?.data?.counts,
          options: [...response?.data?.options]
        })

        setStartDate(convertPublishDateToStartDate(response?.data?.publish_date))

        if (options?.length > 0) {
          setOptionArr(options);
          options?.forEach((element) => {
            setValue(`option ${element?.id}`, element?.value);
          });
        }
        // setImgUrl(`${config?.apiUrl}/${response?.data?.hero_image?.file_path}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (id) {
      fetchOpinionPollById(id);
    }
  }, [id]);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/${apiEndpoints.opinionPollsById(id)}`)
          .then((response) => {
            fetchOpinonPolls();
            toast.success("Opinion poll deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };
  return {
    id,
    register,
    handleSubmit,
    errors,
    optionArr,
    setOptionArr,
    handleAddOptionFields,
    handleRemoveOptionFields,
    onSubmit,
    onUpdate,
    HandleUploadImage,
    handleOptionChange,
    handleDelete,
    opinionPolls,
    setOpinionPolls,
    imgUrl,
    setImgUrl,
    setValue,
    getValues,
    formData,
    setFormData,
    statusOptions,
    durationOptions,
    startDate,
    setStartDate,
    convertPublishDateToStartDate
  };
};

export default useOpinionPollHook;
