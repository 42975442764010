import React, { useContext, useEffect, useState } from "react";
import usePageshook from "./hook";
import { Link } from "react-router-dom";
import Pagination from "../../common/pagination";
import moment from "moment";
import { CreateDataApi, GetListApi, UpdateDataApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import Loader from "../../common/utils/loader";
import _, { filter } from "lodash";
import { CheckAccess } from "../../common/utils/roleAccess";
import Swal from "sweetalert2";
import AppContext from "../../appContext";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import config from "../../../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DropBox from "../../common/drop-zone";
import { convertToSlug } from "../../common/count-words-and-characters";
import ImageUpload from "../../common/editor/imageCrop";
import { Modal } from "react-bootstrap";

const BreakingNews = () => {
    const [template, setTemplate] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');
    const [section, setSection] = useState('');
    const [author, setAuthor] = useState('');
    const [breakingNews, setBreakingNews] = useState(null)
    const [filteredBreakingNews, setFilteredBreakingNews] = useState([])
    const [activeBreakingNews, setActiveBreakingNews] = useState(null)
    const [breakingTotalPages, setBreakingTotalPages] = useState(0)
    const [addStoryType, setAddStoryType] = useState(null)
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [searchStories, setSearchStories] = useState([]);
    const [linkStory, setLinkStory] = useState(null)
    const [activeTab, setActiceTab] = useState(false)
    const [dbUpdating, setDbUpdating] = useState(false);
    const [linkStoryLoading, setLinkStoryLoading] = useState(false)
    const [linkStoryError, setLinkStoryError] = useState(false)

    const [droppableId, setDroppableId] = useState('hello-stories');

    const [heroImageDetails, setHeroImageDetails] = useState({})
    const [selectedFile, setSelectedFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isImgEditorShownForEditor, setIsImgEditorShownForEditor] = useState(false);
    const [cardIndexObj, setCardIndexObj] = useState({ cardIndex: 0, subCardIndex: 0 });

    const [breakingTitle, setBreakingTitle] = useState(null)
    const [breakingDescription, setBreakingDescription] = useState(null)
    const [linkStoryBreakingTitle, setLinkStoryBreakingTitle] = useState(null)
    const [linkStoryBreakingDescription, setLinkStoryBreakingDescription] = useState(null)
    const [editStory, setEditStory] = useState(null)
    const [isPublishing, setIsPublishing] = useState(false)

    const [generalSettings, setGeneralSettings] = useState({})
    const breakingTitleLen = 90
    const breakingDescriptionLen = 120

    useEffect(() => {
        if (activeBreakingNews?.length) {
            setDroppableId('stories');
        }
    }, [activeBreakingNews?.length]);

    const {
        totalPages,
        storiesList,
        handleDelete,
        currentPage,
        handlePageChange,
        navigate,
        fetchOurStory,
        loading,
        setLoading,
        setStoriesList,
        authorList,
        sections,

        errors,
        register,
        setValue,
        imgUrl,
        setImgUrl,
        isImgEditorShown,
        setIsImgEditorShown,
        currentImgId,
        setCurrentImgId,
        getValues,
        onAutoSaveStory,
        setStoryStatus,
        setFormData,
        slug,
        handleSubCardChange
    } = usePageshook();


    const setStoryInfo = (story) => {
        setEditStory(story)

        setTemplate(story ? 'Text Story' : null)
        setAddStoryType(story ? 'Text Story' : null)

        setBreakingTitle(story?.breaking_title || "")
        setBreakingDescription(story?.breaking_description || "")

        setCurrentImgId(story?.hero_image?.file_id || "");
        setValue("file_name", story?.hero_image?.file_name || "");
        setValue("file_path", story?.hero_image?.file_path || "");
        setValue("file_id", story?.hero_image?.file_id || "");

        setImgUrl(story ? `${config?.fileUrl}/${story?.hero_image?.file_path}` : null);

        setValue('caption', story?.hero_image?.caption || "")
        setValue('alt', story?.hero_image?.alt || "")
        setValue('credit', story?.hero_image?.credit || "")


        setHeroImageDetails({
            file_name: story?.hero_image?.file_name,
            file_path: story?.hero_image?.file_path,
            file_id: story?.hero_image?.file_id,

            caption: story?.hero_image?.caption,
            credit: story?.hero_image?.credit,
            alt: story?.hero_image?.alt,
        })
    }

    const { setAppData } = useContext(AppContext);
    const [isTab, setIsTab] = useState("All");
    const userData = localStorage.getItem("user");
    const user = userData ? JSON.parse(userData) : null;

    useEffect(() => {
        setAppData("");

        const fetchBreakingNews = async () => {
            const url = `${config.apiUrl}/stories?page=1&pageSize=100&pagination=true&is_breaking_news=true&is_breaking_news_only=true&isBackend=true`
            const response = await fetch(url)
            const data = await response.json()

            if (data && data.stories) {
                data.stories.sort((a, b) => {
                    const dateA = new Date(a.updated_on);
                    const dateB = new Date(b.updated_on);
                    return dateB - dateA;
                });

                data.stories.sort((a, b) => {
                    return (b.sort_order - a.sort_order)
                })

                setBreakingNews(data?.stories)
                setFilteredBreakingNews(data?.stories)
                setBreakingTotalPages(data?.pages / 20 + (data.pages % 20))
            }
        }

        if (!breakingNews) fetchBreakingNews()

        const fetchGeneralSettings = async () => {
            const data = await GetListApi('general-settings')
            setGeneralSettings(data)
        }

        fetchGeneralSettings()
    }, []);

    const publishBreakingClick = async () => {
        
        if (addStoryType === 'Text Story') {
            const breakingTitle = (document.getElementById("breaking_title_text")?.value || "").trim();
            const breakingDescription = (document.getElementById("breaking_description_text")?.value || "").trim();

            const file_name = heroImageDetails?.file_name
            const file_path = heroImageDetails?.file_path
            const file_id = heroImageDetails?.file_id

            const caption = heroImageDetails?.caption
            const alt = heroImageDetails?.alt || 'alt'
            const credit = heroImageDetails?.credit

            // console.log({
            //     breakingTitle,
            //     breakingDescription,
            //     file_name,
            //     file_path,
            //     file_id,

            //     caption,
            //     credit,
            //     alt
            // })

            if (!breakingTitle) {
                Swal.fire({
                    title: "Breaking Title Required",
                    icon: "warning",
                });
                return;
            }

            if (!breakingDescription) {
                Swal.fire({
                    title: "Breaking Description Required",
                    icon: "warning",
                });
                return;
            }

            // if (!caption) {
            //     Swal.fire({
            //         title: "Hero Image Caption Required",
            //         icon: "warning",
            //     });
            //     return;
            // }


            // if (!alt) {
            //     Swal.fire({
            //         title: "Hero Image Alt Required",
            //         icon: "warning",
            //     });
            //     return;
            // }


            // if (!credit) {
            //     Swal.fire({
            //         title: "Hero Image Credit Required",
            //         icon: "warning",
            //     });
            //     return;
            // }

            // if (!file_name || !file_path || !file_id || !caption || !alt || !credit || !breakingTitle || !breakingDescription) {
            //     Swal.fire({
            //         title: "Hero details / title / description is missing ",
            //         icon: "warning",
            //     });
            //     return;
            // }

            if (!breakingTitle || !breakingDescription || breakingTitle === '' || breakingDescription === '') return;

            const userData = JSON.parse(localStorage.getItem("user"));

            const authorPayload = {
                id: userData?._id,
                value: userData?._id,
                label: userData?.name
            }

            setIsPublishing(true)

            if (editStory) {
                const updatedStory = await UpdateDataApi(`stories/${editStory?.id || editStory?._id}`, {
                    // title: breakingTitle,
                    breaking_title: breakingTitle,
                    breaking_description: breakingDescription,
                    updated_on: new Date().toISOString(),
                    // is_breaking_news: true,
                    // slug: editStory?.slug,
                    // authors: [authorPayload],
                    // story_type: 'Text Story',
                    // status: 'published',
                    // is_breaking_news_only: true,
                    // publish_date: new Date().toISOString(),
                    // createdAt: new Date().toISOString(),
                    hero_image: {
                        file_name: file_name,
                        file_path: file_path,
                        file_id: file_id,
                        caption: caption,
                        alt: alt,
                        credit: credit
                    }
                });

                if (updatedStory) {
                    window.location.reload()
                }

                if (generalSettings?.enableFacebookSharing) await CreateDataApi(apiEndpoints.createFbFeed(updatedStory?._id))
                return
            }

            const response = await CreateDataApi('stories',
                {
                    title: breakingTitle,
                    breaking_title: breakingTitle,
                    breaking_description: breakingDescription,
                    is_breaking_news: true,
                    slug: new Date().toISOString(),
                    authors: [authorPayload],
                    story_type: 'Text Story',
                    status: 'published',
                    is_breaking_news_only: true,
                    publish_date: new Date().toISOString(),
                    updated_on: new Date().toISOString(),
                    hero_image: {
                        file_name: file_name,
                        file_path: file_path,
                        file_id: file_id,
                        caption: caption,
                        alt: alt,
                        credit: credit
                    }
                }
            )

            const rssPayload = {
                title: breakingTitle,
                sub_title: breakingDescription,
                url: '',
                image: config.awsBaseUrl + '/' + file_path,
                userId: user?._id,
                source: 'system'
            }

            await CreateDataApi(apiEndpoints.breakingToRss, rssPayload)

            if (response) {
                if (generalSettings?.enableFacebookSharing) await CreateDataApi(apiEndpoints.createFbFeed(response?._id))
                window.location.reload()
            }
        }
        else if (addStoryType === 'Link Story') {

            const id = linkStory?.id || linkStory?._id

            // const breaking_title = (document.getElementById("breaking_title_link_story")?.value || "").trim();
            // const breaking_description = (document.getElementById("breaking_description_link_story")?.value || "").trim();

            const breaking_title = linkStoryBreakingTitle
            const breaking_description = linkStoryBreakingDescription

            if (!breaking_title) {
                Swal.fire({
                    title: "Breaking Title Required",
                    icon: "warning",
                });
                return;
            }

            if (!breaking_description) {
                Swal.fire({
                    title: "Breaking Description Required",
                    icon: "warning",
                });
                return;
            }

            if (!id) {
                Swal.fire({
                    title: "Please select a story",
                    icon: "warning",
                });
                return;
            }

            setIsPublishing(true)
            
            try {
                const updatedStory = await UpdateDataApi(`stories/${id}`, { is_breaking_news: true, breaking_title, breaking_description, updated_on: new Date().toISOString() });

                const rssPayload = {
                    title: breaking_title,
                    sub_title: breaking_description,
                    url: config.frontendUrl + '/' + linkStory?.slug_preview,
                    image: config.awsBaseUrl + '/' + linkStory?.hero_image?.file_path,
                    userId: user?._id,
                    source: 'system'
                }

                await CreateDataApi(apiEndpoints.breakingToRss, rssPayload)

                if (updatedStory) {
                    if (generalSettings?.enableFacebookSharing) await CreateDataApi(apiEndpoints.createFbFeed(updatedStory?._id))
                    window.location.reload();
                }
            } catch (error) {
                alert(error.message);
                window.location.reload();
            }
        }
        setIsPublishing(false)
    }


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setValue("status", "open");
        setStoryStatus("open");
        if (name === "title") {
            setValue("title", value);
        }

        if (name === "sub_title") {
            setValue("sub_title", value);
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));

        if (name === "custom_slug") {
            let convertedSlug = convertToSlug(value);
            const updatedCustomSlug = slug
                ? `${slug?.split("/")?.reverse()?.join("/")}/${convertedSlug}`
                : `${convertedSlug}`;
            setValue("slug_preview", updatedCustomSlug);
            setValue("slug", `${updatedCustomSlug}`);
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value;
        setSearchKeyWord(searchText);
        handleSearch()
    };

    const delayedSearch = debounce(async (searchKeyWord) => {
        if (!searchKeyWord || searchKeyWord === '') return
        try {
            setLinkStoryLoading(true)
            const result = await fetch(`${config.apiUrl}/stories?q=${searchKeyWord}&page=1&pageSize=100&pagination=true`);
            const data = await result.json()
            setSearchStories(data?.stories);
            if (data)
                setLinkStoryLoading(false)
            if (data?.stories?.length === 0)
                setLinkStoryError(true)
        } catch (error) {
            setLinkStoryLoading(false)
            console.error("Error fetching search results:", error);
        }
    }, 500);

    const handleSearch = () => {
        if (searchKeyWord.trim() === "") {
            setSearchStories([]);
            return;
        }
        delayedSearch(searchKeyWord);
    };


    const updateInDB = async (sourceId, destinationId, filteredBreakingNews) => {
        setShowSpinner(true)
        const sourceStory = filteredBreakingNews?.[sourceId]
        const destinationStory = filteredBreakingNews?.[destinationId]

        try {
            if (destinationId <= sourceId) {
                for (let i = destinationId; i < sourceId; i++) {
                    await UpdateDataApi(`stories/${filteredBreakingNews?.[i]?.id}`, { sort_order: filteredBreakingNews?.[i + 1]?.sort_order, updated_on: new Date().toISOString() });
                }
            }
            else {
                for (let i = destinationId; i > sourceId; i--) {
                    await UpdateDataApi(`stories/${filteredBreakingNews?.[i]?.id}`, { sort_order: filteredBreakingNews?.[i - 1]?.sort_order, updated_on: new Date().toISOString() });
                }
            }
            await UpdateDataApi(`stories/${sourceStory?.id || sourceStory?._id}`, { sort_order: destinationStory?.sort_order, updated_on: new Date().toISOString() });

            setTimeout(() => {
                setShowSpinner(false);
            }, 1000)
        }
        catch (error) {
            console.error(error)
            setTimeout(() => {
                setShowSpinner(false);
            }, 1000)
        }
    }

    const handleDragEnd = (dragResults) => {
        const sourceId = dragResults?.source?.index
        const destinationId = dragResults?.destination?.index

        const copyOfActiveBreakingNews = _.cloneDeep(activeBreakingNews);

        let tempBreakingNews = [...activeBreakingNews]
        let [sourceRow] = tempBreakingNews.splice(sourceId, 1)
        tempBreakingNews.splice(destinationId, 0, sourceRow)

        tempBreakingNews.forEach((story, index) => {
            story.sort_order = copyOfActiveBreakingNews[index].sort_order;
        });
        updateInDB(sourceId, destinationId, copyOfActiveBreakingNews)

        setActiveBreakingNews(tempBreakingNews)
    }

    const [showSpinner, setShowSpinner] = useState(false);

    return (
        <div >
            <div id="spinner" className={`modal ${showSpinner ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showSpinner ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-2">Updating the DB...</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" tabindex="-1" id="addBreaking" aria-labelledby="addBreakingLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel">Add Breaking Story</h5>
                    <button disabled={isPublishing} onClick={publishBreakingClick} className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        {isPublishing ? <div className="spinner-border spinner-border-sm tc-submit-loading ml-30 mr-30" role="status">  </div> : 'Publish Breaking'}
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="row mb-30 mt-30">
                        <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                            Story Type
                        </label>
                        <div className="col-sm-8">
                            <select
                                name="custom_slug"
                                className="form-control select2"
                                style={{ width: '100%' }}
                                data-placeholder="Select a section"
                                value={template}
                                onChange={(e) => {
                                    if (e.target?.value === 'Link Story') { setStoryInfo(null) }
                                    setTemplate(e.target.value);
                                    setAddStoryType(e.target.value);
                                }}
                            >
                                <option > select story type</option>
                                <option value={'Text Story'}> Text Story</option>
                                <option value={'Link Story'}> Link Story</option>
                            </select>
                        </div>
                    </div>

                    {
                        addStoryType === 'Text Story' &&
                        <>
                            <div className="row mb-30 mt-30">
                                <label htmlFor="breaking_title_text" className="col-sm-4 col-form-label text-right">
                                    Breaking Title
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        name="custom_slug"
                                        className="form-control"
                                        id="breaking_title_text"
                                        value={breakingTitle || ''}
                                        onChange={(e) => setBreakingTitle(e?.target?.value?.slice(0, breakingTitleLen))}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="breaking_description" className="col-sm-4 col-form-label text-right">
                                    Breaking Description
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        name="custom_slug"
                                        className="form-control"
                                        id="breaking_description_text"
                                        value={breakingDescription || ''}
                                        onChange={(e) => setBreakingDescription(e?.target?.value?.slice(0, breakingDescriptionLen))}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30" style={{ width: '150%' }}>
                                <DropBox
                                    errors={errors}
                                    register={register}
                                    handleChange={handleChange}
                                    setValue={setValue}
                                    imgUrl={imgUrl}
                                    setImgUrl={setImgUrl}
                                    files={files}
                                    setFiles={setFiles}
                                    setIsImgEditorShown={setIsImgEditorShown}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    currentImgId={currentImgId}
                                    setCurrentImgId={setCurrentImgId}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    getValues={getValues}
                                    onAutoSaveStory={onAutoSaveStory}
                                    heroImageDetails={heroImageDetails}
                                    setHeroImageDetails={setHeroImageDetails}
                                    editEnabled={true}
                                />
                            </div>
                        </>
                    }

                    {
                        addStoryType === 'Link Story' &&

                        <div>
                            <div className="row mb-30 mt-30">
                                <label htmlFor="breaking_title_link_story" className="col-sm-4 col-form-label text-right">
                                    Breaking Title
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        name="custom_slug"
                                        className="form-control"
                                        id="breaking_title_link_story"
                                        value={linkStoryBreakingTitle}
                                        onChange={(e) => { setLinkStoryBreakingTitle(e?.target?.value?.slice(0, breakingTitleLen)) }}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="breaking_description_link_story" className="col-sm-4 col-form-label text-right">
                                    Breaking Description
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        name="custom_slug"
                                        className="form-control"
                                        id="breaking_description_link_story"
                                        value={linkStoryBreakingDescription}
                                        onChange={(e) => { setLinkStoryBreakingDescription(e?.target?.value?.slice(0, breakingDescriptionLen)) }}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Link Story
                                </label>
                                <div className="search-bar col-sm-8" >
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={linkStory?.title || `Search Stories By Title ...`}
                                        value={linkStory?.title || searchKeyWord}
                                        onChange={(e) => { setLinkStoryError(false); handleSearchInputChange(e); setLinkStory(null) }}
                                        style={{ 'height': '3em' }}
                                    />
                                    {
                                        linkStoryLoading &&
                                        <div className="modal-content">
                                            <div className="modal-body text-center">
                                                <div className="spinner-border" role="status">
                                                </div>
                                                <p className="mt-2">fetching story...</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !linkStoryLoading && !linkStory &&
                                        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                            <ul className="list-group">
                                                {searchStories &&
                                                    searchStories.map((story) => (
                                                        <li
                                                            key={story.id || story?._id}
                                                            className="list-group-item"
                                                            style={{ backgroundColor: 'transparent' }}
                                                            onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#ccc'; }}
                                                            onMouseOut={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                                                        >
                                                            <a
                                                                // href={`/story/create#/story/update/${story.id}`}
                                                                onClick={() => { setLinkStory(story) }}
                                                                style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
                                                            >
                                                                {story.title}
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    }
                                    {
                                        linkStoryError && (searchKeyWord.trim() !== "") &&
                                        <div style={{ color: 'red', fontSize: '12px' }}>
                                            <p>Story Not Found or Invalid Story</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>


            <div className="content">
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">
                        <div className="card-header">
                            <h3 className="text-bold">Breaking News</h3>
                            <input
                                type="search"
                                placeholder="Enter title or author"
                                className="form-control story-search-input"
                                onChange={(e) => {
                                    fetchOurStory("", e?.target?.value);
                                }}
                            />

                            <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#addBreaking" aria-controls="offcanvasRight" onClick={() => { setStoryInfo(null) }}>Add Breaking</button>
                            {/* <button className="btn btn-primary btn-round btn-sm">Filter</button> */}
                        </div>

                        <div className="card-body">
                            <div className={`row mb-15 d-flex justify-content-around`}>
                                <div
                                    className={
                                        isTab === "All"
                                            ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                            : "px-0 col-md-1 text-center text-bold mb-0"
                                    }
                                    role="button"
                                    onClick={(e) => {
                                        setActiceTab(false)
                                        setIsTab("All");
                                        // setFilteredBreakingNews(breakingNews)
                                        setBreakingTotalPages(breakingNews.length / 20)

                                        const copyOfActiveBreakingNews2 = _.cloneDeep(breakingNews)

                                        activeBreakingNews.forEach((story) => {
                                            const foundIndex = copyOfActiveBreakingNews2.findIndex((story2) => (story2.id === story.id || story2?._id === story?._id));
                                            if (foundIndex !== -1) {
                                                copyOfActiveBreakingNews2[foundIndex].sort_order = story.sort_order;
                                            }
                                        });

                                        copyOfActiveBreakingNews2.sort((a, b) => {
                                            return (b.sort_order - a.sort_order)
                                        })

                                        setFilteredBreakingNews(copyOfActiveBreakingNews2)
                                    }}
                                >
                                    All
                                </div>
                                <div
                                    className={
                                        isTab === "Open"
                                            ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                            : "px-0 col-md-1 text-center text-bold mb-0"
                                    }
                                    role="button"
                                    onClick={(e) => {
                                        setFilteredBreakingNews(null)
                                        setActiceTab(true)
                                        setIsTab("Open");

                                        const twoHoursInMillis = 2 * 60 * 60 * 1000;
                                        const currentTime = new Date().getTime()

                                        let data = null

                                        data = breakingNews.filter(story => {
                                            const publish_date = new Date(story.publish_date).getTime();
                                            const timeDifference = Math.abs(currentTime - publish_date);
                                            return timeDifference <= twoHoursInMillis;
                                        })

                                        if (data && !activeBreakingNews) {

                                            data.sort((a, b) => {
                                                return (b.sort_order - a.sort_order)
                                            })

                                            setActiveBreakingNews(data)

                                            const so = []
                                            data.forEach(story => so.push(story?.sort_order))
                                            console.log({ sort_order: so })
                                        }
                                    }}
                                >
                                    Active
                                </div>
                                <div
                                    className={
                                        isTab === "Submitted"
                                            ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                            : "px-0 col-md-1 text-center text-bold mb-0"
                                    }
                                    role="button"
                                    onClick={(e) => {
                                        setActiceTab(false)
                                        setIsTab("Submitted");

                                        const twoHoursInMillis = 2 * 60 * 60 * 1000;
                                        const currentTime = new Date().getTime()

                                        const data =
                                            breakingNews.filter(story => {
                                                const publish_date = new Date(story.publish_date).getTime();
                                                const timeDifference = Math.abs(currentTime - publish_date);
                                                return timeDifference > twoHoursInMillis;
                                            })

                                        data.sort((a, b) => {
                                            return (b.sort_order - a.sort_order)
                                        })

                                        setFilteredBreakingNews(data)
                                        setBreakingTotalPages(data.length / 20)
                                    }}
                                >
                                    InActive
                                </div>
                            </div>


                            <div className="table-responsive" style={{ display: activeTab ? 'none' : 'block' }}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Author</th>
                                            <th>Story Type</th>
                                            <th>Story Status</th>
                                            <th>Created On</th>
                                            <th>Breaking News</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!filteredBreakingNews || loading ? (
                                            <Loader />
                                        ) : (
                                            filteredBreakingNews?.map((story, index) => {
                                                return (
                                                    <tr key={story?._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{story?.title}</td>
                                                        {/* <td>{story.sub_title}</td> */}
                                                        <td className="text-capitalize">
                                                            {story?.authors?.length > 0
                                                                ? ConvertArrayTOString(
                                                                    story?.authors,
                                                                    "collection"
                                                                )
                                                                : "-"}
                                                        </td>

                                                        <td>{story?.story_type}</td>
                                                        <td className="text-capitalize">{story?.status}</td>

                                                        {/* <td>{story.created_by || `-`}</td> */}
                                                        <td>
                                                            {
                                                                moment.utc(story?.updatedAt).local().format("YYYY-MM-DD | hh:mm:ss a")
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="icheckbox_flat-green">
                                                                {CheckAccess("story", "update") ? (
                                                                    <input
                                                                        type="checkbox"
                                                                        id="is_breaking_news"
                                                                        name="is_breaking_news"
                                                                        className="chk-col-grey"
                                                                        onChange={async (e) => {
                                                                            if (CheckAccess("story", "update")) {
                                                                                await UpdateDataApi(
                                                                                    `${apiEndpoints.storiesById(
                                                                                        story?._id
                                                                                    )}`,
                                                                                    { is_breaking_news: e.target.checked }
                                                                                );
                                                                                fetchOurStory();
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: "You don't have permission.",
                                                                                    text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                    icon: "warning",
                                                                                });
                                                                            }
                                                                        }}
                                                                        checked={story?.is_breaking_news}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type="checkbox"
                                                                        id="is_breaking_news"
                                                                        name="is_breaking_news"
                                                                        className="chk-col-grey"
                                                                        onChange={(e) => {
                                                                            e?.preventDefault();
                                                                            Swal.fire({
                                                                                title: "You don't have permission.",
                                                                                text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                icon: "warning",
                                                                            });
                                                                        }}
                                                                        checked={story?.is_breaking_news}
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="d-flex align-items-center">
                                                            {CheckAccess("story", "view") && (
                                                                <div
                                                                    onClick={() =>
                                                                        navigate(`/story/view/${story?._id}`)
                                                                    }
                                                                    className="mr-2"
                                                                    role="button"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </div>
                                                            )}
                                                            {story?.status === "approved" ? (
                                                                <>
                                                                    {CheckAccess("story", "update") &&
                                                                        CheckAccess(
                                                                            "story",
                                                                            "update approved story"
                                                                        ) && (
                                                                            <div
                                                                                onClick={() => {
                                                                                    if (story?.is_breaking_news_only) {
                                                                                        document.querySelector('[data-bs-target="#addBreaking"]').click();
                                                                                        setStoryInfo(story)
                                                                                    }
                                                                                    else if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Video Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/video-story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }

                                                                                    else if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Text Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }

                                                                                    else if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Web Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/web-story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                    else if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Photo Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/photo-story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                    setAppData({ story_Id: story?._id });
                                                                                }}
                                                                                className="mr-2"
                                                                                role="button"
                                                                            >
                                                                                <i className="fa fa-edit"></i>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                CheckAccess("story", "update") && (
                                                                    <div
                                                                        onClick={() => {
                                                                            if (story?.is_breaking_news_only) {
                                                                                document.querySelector('[data-bs-target="#addBreaking"]').click();
                                                                                setStoryInfo(story)
                                                                            }
                                                                            else if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Video Story")
                                                                            ) {
                                                                                navigate(
                                                                                    `/video-story/update/${story?._id}`
                                                                                );
                                                                                return;
                                                                            }

                                                                            else if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Text Story")
                                                                            ) {
                                                                                navigate(`/story/update/${story?._id}`);
                                                                                return;
                                                                            }

                                                                            else if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Web Story")
                                                                            ) {
                                                                                navigate(
                                                                                    `/web-story/update/${story?._id}`
                                                                                );
                                                                                return;
                                                                            }
                                                                            else if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Photo Story")
                                                                            ) {
                                                                                navigate(
                                                                                    `/photo-story/update/${story?._id}`
                                                                                );
                                                                                return;
                                                                            }
                                                                            setAppData({ story_Id: story?._id });
                                                                        }}
                                                                        className="mr-2"
                                                                        role="button"
                                                                    >
                                                                        <i className="fa fa-edit" ></i>
                                                                    </div>
                                                                )
                                                            )}
                                                            {CheckAccess("story", "delete") && (
                                                                <div
                                                                    onClick={(e) => {
                                                                        e?.preventDefault();
                                                                        handleDelete(story?._id);
                                                                    }}
                                                                    role="button"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                                {!loading && totalPages && storiesList?.length > 0 ? (
                                    <div className={`container`}>
                                        <div className={`row`}>
                                            <div
                                                className={`col-sm-12 d-flex justify-content-center px-0`}
                                            >
                                                <Pagination
                                                    currentPage={1}
                                                    totalPages={breakingTotalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>



                            <div className="table-responsive" style={{ display: activeTab ? 'block' : 'none' }}>
                                <DragDropContext onDragEnd={(dragResults) => handleDragEnd(dragResults)} >
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Author</th>
                                                <th>Story Type</th>
                                                <th>Story Status</th>
                                                <th>Created On</th>
                                                <th>Breaking News</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId={droppableId}>
                                            {
                                                (provided) => (
                                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                                        {
                                                            !activeBreakingNews || loading ? (
                                                                <Loader />
                                                            ) :
                                                                (
                                                                    activeBreakingNews?.map((story, index) => {
                                                                        return (
                                                                            <Draggable draggableId={story?.id || story?._id} index={index} key={story?.id || story?._id}>
                                                                                {
                                                                                    (provided) => (
                                                                                        <tr key={story?.id || story?._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>{story?.title}</td>
                                                                                            <td className="text-capitalize">
                                                                                                {story?.authors?.length > 0
                                                                                                    ? ConvertArrayTOString(
                                                                                                        story?.authors,
                                                                                                        "collection"
                                                                                                    )
                                                                                                    : "-"}
                                                                                            </td>

                                                                                            <td>{story?.story_type}</td>
                                                                                            <td className="text-capitalize">{story?.status}</td>

                                                                                            {/* <td>{story.created_by || `-`}</td> */}
                                                                                            <td>
                                                                                                {moment(story?.timestamp).format(
                                                                                                    "MMMM DD, YYYY | hh:mm:ss a"
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="icheckbox_flat-green">
                                                                                                    {CheckAccess("story", "update") ? (
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            id="is_breaking_news"
                                                                                                            name="is_breaking_news"
                                                                                                            className="chk-col-grey"
                                                                                                            onChange={async (e) => {
                                                                                                                if (CheckAccess("story", "update")) {
                                                                                                                    await UpdateDataApi(
                                                                                                                        `${apiEndpoints.storiesById(
                                                                                                                            story?._id
                                                                                                                        )}`,
                                                                                                                        { is_breaking_news: e.target.checked }
                                                                                                                    );
                                                                                                                    fetchOurStory();
                                                                                                                } else {
                                                                                                                    Swal.fire({
                                                                                                                        title: "You don't have permission.",
                                                                                                                        text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                                                        icon: "warning",
                                                                                                                    });
                                                                                                                }
                                                                                                            }}
                                                                                                            checked={story?.is_breaking_news}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            id="is_breaking_news"
                                                                                                            name="is_breaking_news"
                                                                                                            className="chk-col-grey"
                                                                                                            onChange={(e) => {
                                                                                                                e?.preventDefault();
                                                                                                                Swal.fire({
                                                                                                                    title: "You don't have permission.",
                                                                                                                    text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                                                    icon: "warning",
                                                                                                                });
                                                                                                            }}
                                                                                                            checked={story?.is_breaking_news}
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="d-flex align-items-center">
                                                                                                {CheckAccess("story", "view") && (
                                                                                                    <div
                                                                                                        onClick={() =>
                                                                                                            navigate(`/story/view/${story?._id}`)
                                                                                                        }
                                                                                                        className="mr-2"
                                                                                                        role="button"
                                                                                                    >
                                                                                                        <i className="fa fa-eye"></i>
                                                                                                    </div>
                                                                                                )}
                                                                                                {story?.status === "approved" ? (
                                                                                                    <>
                                                                                                        {CheckAccess("story", "update") &&
                                                                                                            CheckAccess(
                                                                                                                "story",
                                                                                                                "update approved story"
                                                                                                            ) && (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (story?.is_breaking_news_only) {
                                                                                                                            document.querySelector('[data-bs-target="#addBreaking"]').click();
                                                                                                                            setStoryInfo(story)
                                                                                                                        }
                                                                                                                        else if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Video Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/video-story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }

                                                                                                                        else if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Text Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }

                                                                                                                        else if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Web Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/web-story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }
                                                                                                                        else if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Photo Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/photo-story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }
                                                                                                                        setAppData({ story_Id: story?._id });
                                                                                                                    }}
                                                                                                                    className="mr-2"
                                                                                                                    role="button"
                                                                                                                >
                                                                                                                    <i className="fa fa-edit"></i>
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    CheckAccess("story", "update") && (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                if (story?.is_breaking_news_only) {
                                                                                                                    document.querySelector('[data-bs-target="#addBreaking"]').click();
                                                                                                                    setStoryInfo(story)
                                                                                                                }
                                                                                                                else if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Video Story")
                                                                                                                ) {
                                                                                                                    navigate(
                                                                                                                        `/video-story/update/${story?._id}`
                                                                                                                    );
                                                                                                                    return;
                                                                                                                }

                                                                                                                else if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Text Story")
                                                                                                                ) {
                                                                                                                    navigate(`/story/update/${story?._id}`);
                                                                                                                    return;
                                                                                                                }

                                                                                                                else if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Web Story")
                                                                                                                ) {
                                                                                                                    navigate(
                                                                                                                        `/web-story/update/${story?._id}`
                                                                                                                    );
                                                                                                                    return;
                                                                                                                }
                                                                                                                else if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Photo Story")
                                                                                                                ) {
                                                                                                                    navigate(
                                                                                                                        `/photo-story/update/${story?._id}`
                                                                                                                    );
                                                                                                                    return;
                                                                                                                }
                                                                                                                setAppData({ story_Id: story?._id });
                                                                                                            }}
                                                                                                            className="mr-2"
                                                                                                            role="button"
                                                                                                        >
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                                {CheckAccess("story", "delete") && (
                                                                                                    <div
                                                                                                        onClick={(e) => {
                                                                                                            e?.preventDefault();
                                                                                                            handleDelete(story?._id);
                                                                                                        }}
                                                                                                        role="button"
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </div>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </Draggable>
                                                                        );
                                                                    })
                                                                )
                                                        }
                                                        {provided.placeholder}
                                                    </tbody>
                                                )
                                            }
                                        </Droppable>
                                    </table>
                                </DragDropContext>


                                {!loading && totalPages && storiesList?.length > 0 ? (
                                    <div className={`container`}>
                                        <div className={`row`}>
                                            <div
                                                className={`col-sm-12 d-flex justify-content-center px-0`}
                                            >
                                                <Pagination
                                                    currentPage={1}
                                                    totalPages={breakingTotalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>




                        </div>
                    </div>
                </div>
            </div>


            <Modal
                // {...props}
                show={isImgEditorShown}
                // fullscreen={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                onHide={() => setIsImgEditorShown(false)}
                dialogClassName="story-popup"
                contentClassName="story-content-popup"
            >
                <Modal.Body>
                    <ImageUpload
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        isImgEditorShown={isImgEditorShown}
                        setIsImgEditorShown={setIsImgEditorShown}
                        isImgEditorShownForEditor={isImgEditorShownForEditor}
                        setIsImgEditorShownForEditor={setIsImgEditorShownForEditor}
                        setValue={setValue}
                        setImgUrl={setImgUrl}
                        handleSubCardChange={handleSubCardChange}
                        cardIndexObj={cardIndexObj}
                        currentImgId={currentImgId}
                        setCurrentImgId={setCurrentImgId}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                </Modal.Body>
            </Modal>
        </div >
    );
};

export default BreakingNews;
