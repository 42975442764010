import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import config from "../../../config";
import { apiEndpoints } from "../api/apiEndpoints";
import styles from "./dropzone.module.css";
import { DeleteApi } from "../api/api";
const DropBox = (props) => {
  const {
    errors,
    register,
    setValue,
    imgUrl,
    setImgUrl,
    files,
    setFiles,
    setIsImgEditorShown,
    isComponent,
    //from text editor component props
    cardIndex,
    subCardIndex,
    handleSubCardChange,
    subCard,
    cards,
    selectedFile,
    setSelectedFile,
    setIsImgEditorShownForEditor,
    setCardIndexObj,
    currentImgId,
    setCurrentImgId,
    isLoading,
    setIsLoading,
    getValues,
    heroImageDetails,
    setHeroImageDetails,
    editEnabled
  } = props;

  const [heroImageLoad, setHeroImageLoad] = useState(false);
  const HandleUploadImage = async (payload) => {
    // let data = {};
    // const file = files[0];
    try {
      setIsLoading(true);
      setHeroImageLoad(true);
      // if (files?.length > 0) {
      if (payload) {
        // const formData = new FormData();
        // data.status = "Active";
        // const res = await axios.post(
        //   `${config?.fileUrl}/${apiEndpoints.upload}`,
        //   { files: files[0] },
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   } 
        // ); 

        const res = payload

        if (res?.data?.length > 0) {
          if (setHeroImageDetails && heroImageDetails)
            setHeroImageDetails({ ...heroImageDetails, file_name: res?.data?.[0]?.file_name, file_path: res?.data?.[0]?.file_path, file_id: res?.data?.[0]?._id })

          if (isComponent === "textEditor") {
            setIsLoading(false);
            setHeroImageLoad(false);
            handleSubCardChange(
              cardIndex,
              subCardIndex,
              "images",
              res?.data[0]?.file_path
            );
            handleSubCardChange(
              cardIndex,
              subCardIndex,
              "file_Id",
              res?.data[0]?._id
            );

            let nameParts = (res?.data[0]?.original_name || "")
            nameParts = nameParts?.split('.')?.length > 1 ? nameParts.split('.').slice(0, -1).join('.') : nameParts;

            handleSubCardChange(
              cardIndex,
              subCardIndex,
              "img_alt",
              nameParts
            );

          } else {
            setIsLoading(false);
            setHeroImageLoad(false);
            setCurrentImgId(res?.data[0]?._id);
            setValue("file_name", res?.data[0]?.file_name);
            setValue("file_path", res?.data[0]?.file_path);
            setValue("file_id", res?.data[0]?._id);

            let nameParts = (res?.data[0]?.original_name || "")
            nameParts = nameParts?.split('.')?.length > 1 ? nameParts.split('.').slice(0, -1).join('.') : nameParts;

            setValue("alt", nameParts)
            setImgUrl(`${config?.fileUrl}/${res?.data[0]?.file_path}`);
          }
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const uploadImageToS3 = async (files) => {
    setIsLoading(true)
    axios.post(
      `${config?.apiUrl}/upload-files/image/s3`,
      { files: files[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then(res => {
        const payload = { data: [res?.data?.uploadedFiles?.[0]] }
        console.log({ payload })
        HandleUploadImage(payload)
      })
      .catch(error => {
        console.error("Error: " + error.message)
        setIsLoading(false)
      })
  };


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      // setImgUrl("");
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setSelectedFile(acceptedFiles[0]);
      uploadImageToS3(acceptedFiles);
      // HandleUploadImage(acceptedFiles);
    },
  });
  const handleRootClick = (e) => {
    e.preventDefault(); // Prevent the default behavior
  };
  const imgPrefix = cards?.[0]?.is_migrated ? config.migratedFileUrl : config.fileUrl
  const thumbs = imgUrl ? (
    <div className={styles.thumb}>
      <div className={styles.thumbInner}>
        {subCard?.images && isComponent === "textEditor" ? (
          <>
            <img
              src={`${imgUrl?.includes('media') ? config?.migratedFileUrl : config?.fileUrl}/${subCard?.images}`}
              className={styles.img}
            />
          </>
        ) : (
          <img src={imgUrl} className={styles.img} />
        )}
      </div>
    </div>
  ) : (
    files?.map((file) => (
      <div key={file.name} className={`d-flex justify-content-center`}>
        <img
          src={file?.preview}
          className={styles.img}
          onLoad={() => {
            URL.revokeObjectURL(file?.preview);
          }}
        />
      </div>
    ))
  );
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file?.preview));
  }, []);
  const HandleImageDelete = async (imgId) => {
    const response = await DeleteApi(
      `${apiEndpoints.uploadById(
        isComponent === "textEditor" ? imgId : currentImgId
      )}`
    );
    if (response.status === 204) {
      if (isComponent === "textEditor") {
        handleSubCardChange(cardIndex, subCardIndex, "images", "");
        handleSubCardChange(cardIndex, subCardIndex, "file_Id", "");
      } else {
        setCurrentImgId("");
        setValue("file_name", "");
        setValue("file_path", "");
        setValue("file_id", "");

        setValue("caption", "");
        setValue("attribution", "");
        setValue("credit", "");
      }
      setFiles([]);
      setImgUrl("");
    }
  };

  const HandleImageEdit = async (url) => {
    setIsImgEditorShown(true);
  };
  const editorThumbs = subCard?.images && (
    <div className={styles.thumb}>
      <div className={styles.thumbInner}>
        <img
          src={`${imgUrl.includes('media') ? config?.migratedFileUrl : config?.fileUrl}/${cards[cardIndex]?.sub_card[subCardIndex]?.images}`}
          className={styles.img}
        />
      </div>
    </div>
  );

  const fetchImage = async (file_url) => {
    try {
      const url = `${config.apiUrl}/proxy/fetchImage`
      
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ imageUrl: file_url })
      })

      const blob = await response.blob();

      const fileName = "image.png";
      const lastModified = new Date();

      // Create a File object
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: lastModified.getTime(),
      });
      setSelectedFile(file);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };
  return (
    <>
      {!subCard?.file_Id && isComponent !== "textEditor" ? (
        <div className="row mb-15 position-relative">
          {heroImageLoad && !subCard?.file_Id ? (
            <div className="spinner text-editor-loader"></div>
          ) : (
            ""
          )}
          <label
            htmlFor="heroImage"
            className="col-sm-2 col-form-label text-start"
          >
            Hero Image
          </label>
          <div className="col-sm-7">
            {!imgUrl && !files?.length > 0 ? (
              <div
                {...getRootProps({
                  className: "dropzone border p-3 text-center hero-image",
                })}
              >
                <input {...getInputProps()} />
                <i className="fa fa-cloud-upload"></i>
                <p>Upload Image</p>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 col-md-7 px-0 hero-image dropzone border position-relative">
                  {thumbs}

                  {
                    editEnabled &&
                    <div
                      className={`d-flex position-absolute ${styles.heroImgBtns}`}
                    >
                      <button
                        className={`btn btn-primary btn-small text-dark`}
                        onClick={(e) => {
                          HandleImageDelete(getValues()?.file_id);
                          e?.preventDefault();
                        }}
                        disabled={heroImageLoad}
                      >
                        <i className="text-white  fa fa-trash"></i>
                      </button>
                      <button
                        {...getRootProps({ onClick: handleRootClick })}
                        className={`btn btn-primary btn-small text-dark mx-2`}
                        disabled={heroImageLoad}
                      >
                        <i className="text-white  fa fa-cloud-upload"></i>
                      </button>
                      <button
                        className={`btn btn-primary btn-small text-dark`}
                        onClick={(e) => {
                          e?.preventDefault();
                          HandleImageEdit(imgUrl);
                          fetchImage(imgUrl);
                        }}
                        disabled={heroImageLoad}
                      >
                        <i className="text-white  fa fa-edit"></i>
                      </button>
                    </div>
                  }

                </div>
                <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-around">
                  <label
                    htmlFor="title"
                    className="col-form-label text-bold mb-0"
                  >
                    Caption
                  </label>
                  <div>
                    <input
                      disabled={!editEnabled}
                      type="text"
                      name="caption"
                      className="form-control mb-3"
                      placeholder="Enter Caption"
                      {...register("caption", {
                        required: "Caption is required",
                      })}
                      onChange={(e) => { if (setHeroImageDetails && heroImageDetails) setHeroImageDetails({ ...heroImageDetails, caption: e?.target?.value }) }}
                    />
                    {errors.caption && (
                      <span className="text-danger">
                        {errors.caption.message}
                      </span>
                    )}
                  </div>
                  <label
                    htmlFor="title"
                    className="col-form-label text-bold mb-0"
                  >
                    Alt
                  </label>
                  <div>
                    <input
                      disabled={!editEnabled}
                      type="text"
                      name="alt"
                      className="form-control mb-3"
                      placeholder="Enter Attribution"
                      {...register("alt", {
                        required: "Attribution is required",
                      })}
                      onChange={(e) => { if (setHeroImageDetails && heroImageDetails) setHeroImageDetails({ ...heroImageDetails, alt: e?.target?.value }) }}
                    />
                    {errors.alt && (
                      <span className="text-danger">{errors.alt.message}</span>
                    )}
                  </div>
                  <label
                    htmlFor="title"
                    className="col-form-label text-bold mb-0"
                  >
                    Credit
                  </label>
                  <div>
                    <input
                      disabled={!editEnabled}
                      type="text"
                      name="credit"
                      className="form-control mb-3"
                      placeholder="Enter Credit"
                      {...register("credit", {
                        required: "Credit is required",
                      })}
                      onChange={(e) => { if (setHeroImageDetails && heroImageDetails) setHeroImageDetails({ ...heroImageDetails, credit: e?.target?.value }) }}
                    />
                    {errors.credit && (
                      <span className="text-danger">
                        {errors.credit.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={`position-relative`}>
          {!subCard?.images && subCard?.file_Id !== "" ? (
            <div
              {...getRootProps({
                className: "dropzone border p-3 text-center hero-image",
              })}
            >
              <input {...getInputProps()} />
              <i className="fa fa-cloud-upload"></i>
              <p>Upload Image</p>
            </div>
          ) : (
            <div className="row display-flex justify-content-center">
              {isLoading && !subCard?.images ? (
                <div className="spinner text-editor-loader"></div>
              ) : (
                ""
              )}
              <div className="col-sm-12 col-md-6 px-0 hero-image dropzone border position-relative">
                {editorThumbs}
                {
                  editEnabled &&

                  <div
                    className={`d-flex position-absolute ${styles.heroImgBtns}`}
                  >
                    <button
                      className={`btn btn-primary btn-small text-light`}
                      onClick={(e) => {
                        e?.preventDefault();
                        HandleImageDelete(subCard?.file_Id);
                      }}
                      disabled={isLoading}
                    >
                      <i className="text-white fa fa-trash"></i>
                    </button>
                    <button
                      {...getRootProps({ onClick: handleRootClick })}
                      className={`btn btn-primary btn-small text-light`}
                      disabled={isLoading}
                    >
                      <i className="text-white fa fa-cloud-upload"></i>
                    </button>
                    <button
                      className={`btn btn-primary btn-small text-light`}
                      onClick={(e) => {
                        e?.preventDefault();
                        if (isComponent === "textEditor") {
                          setIsLoading(false);
                          setIsImgEditorShownForEditor(true);
                          setCardIndexObj({
                            cardIndex: cardIndex,
                            subCardIndex: subCardIndex,
                          });
                          // fetchImage(subCard?.images)
                          fetchImage(`${config?.fileUrl}/${subCard?.images}`);
                        } else {
                          setIsImgEditorShown(true);
                        }
                      }}
                      disabled={isLoading}
                    >
                      <i className="text-white fa fa-edit"></i>
                    </button>
                  </div>
                }
              </div>
              <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-around">
                <label
                  htmlFor="title"
                  className="col-form-label text-bold mb-0"
                >
                  Caption
                </label>
                <input
                  disabled={!editEnabled}
                  type="text"
                  name="img_caption"
                  className="form-control mb-3 width-100"
                  placeholder="Enter Caption"
                  {...register(
                    `cards[${cardIndex}].sub_card[${subCardIndex}].img_caption`
                  )}
                  value={subCard.img_caption || (imgUrl?.includes('media') ? cards[cardIndex].sub_card[subCardIndex].title : '')}
                  onChange={(e) =>
                    handleSubCardChange(
                      cardIndex,
                      subCardIndex,
                      "img_caption",
                      e.target.value
                    )
                  }
                />
                <label
                  htmlFor="title"
                  className="col-form-label text-bold mb-0"
                >
                  Alt
                </label>
                <input
                  disabled={!editEnabled}
                  type="text"
                  name="img_alt"
                  className="form-control mb-3 width-100"
                  placeholder="Enter Attribution"
                  {...register(
                    `cards[${cardIndex}].sub_card[${subCardIndex}].img_alt`
                  )}
                  value={subCard.img_alt || (imgUrl?.includes('media') ? cards[cardIndex].sub_card[subCardIndex].attribution : '')}
                  onChange={(e) =>
                    handleSubCardChange(
                      cardIndex,
                      subCardIndex,
                      "img_alt",
                      e.target.value
                    )
                  }
                />
                <label
                  htmlFor="title"
                  className="col-form-label text-bold mb-0"
                >
                  Credit
                </label>
                <input
                  disabled={!editEnabled}
                  type="text"
                  name="img_credit"
                  className="form-control mb-3 width-100"
                  placeholder="Enter Credit"
                  {...register(
                    `cards[${cardIndex}].sub_card[${subCardIndex}].img_credit`
                  )}
                  value={subCard.img_credit || (imgUrl?.includes('media') ? cards[cardIndex].sub_card[subCardIndex].attribution : '')}
                  onChange={(e) =>
                    handleSubCardChange(
                      cardIndex,
                      subCardIndex,
                      "img_credit",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DropBox;
